import React from 'react';
import './Services.css';
import { Card, CardContent, Typography } from '@material-ui/core';

const servicesList = [
  'Financial Planning',
  'Investment Management',
  'Retirement Strategies',
  'Tax Planning'
];

const Services = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>
      <div className="services-container">
        {servicesList.map((service, index) => (
          <Card key={index} style={{ margin: '10px', width: '300px' }}>
            <CardContent>
              <Typography variant="body1">{service}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Services;
