import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/Images/lune-management-logo.png';
import { IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const Navbar = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const links = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Services", path: "/services" },
    { name: "Contact", path: "/contact" },
  ];

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <nav className="navbar">
       <a href="/">
        <img src={logo} alt="Lune Management Logo" className="navbar-logo" />
      </a>
      <IconButton edge="start" color="inherit" aria-label="menu" className="hamburger" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer className="mobile-menu" anchor={'top'} open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {links.map((link, index) => (
            <ListItem button key={index} component={Link} to={link.path} onClick={toggleDrawer(false)}>
              <ListItemText primary={link.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <ul className="navbar-nav">
        {links.map((link, index) => (
          <li key={index}><Link to={link.path} className={location.pathname === link.path ? 'active' : ''}>{link.name}</Link></li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
