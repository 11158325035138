import React, { useState } from "react";
import logo from "../assets/Images/lune-management-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./ComingSoon.css";
import Contact from "../Contact/Contact"; // Ensure the correct path

const ComingSoon = ({ onAccessGranted }) => {
  const [password, setPassword] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const correctPassword = process.env.REACT_APP_CORRECT_PASSWORD;

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) onAccessGranted();
    else setAccessDenied(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="coming-soon-page">
      <div className="coming-soon-container">
        <img
          src={logo}
          alt="UNE Management Logo"
          className="coming-soon-logo"
        />
        <h2 className="coming-soon-text">Business Management X Concierge</h2>
        {accessDenied && (
          <p className="access-denied">Access Denied. Incorrect Access Code.</p>
        )}
        <form onSubmit={handleFormSubmit} className="coming-soon-form">
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Access code"
              className="coming-soon-input"
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className="password-toggle-icon"
            />
          </div>
          <button type="submit" className="coming-soon-button">
            Enter
          </button>
        </form>
      </div>
      <Contact />
    </div>
  );
};

export default ComingSoon;
