import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      {/* style={{backgroundImage: `url(${require('../assets/Images/financePicture.jpg')})`, backgroundSize: 'cover'}} */}
      <div className="welcomBanner" >
        <h1>Welcome to Lune management</h1>
        <h2 className="slogan"><span className="typing-animation">Guiding Success & Illuminating Potential</span></h2>
      </div>
      <p>We offer a variety of financial services to help you achieve your financial goals. Explore our website to learn more about our services, or contact us directly for a consultation.</p>
    </div>
  );
};

export default Home;
