import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>We are a finance company with a passion for helping our clients achieve their financial goals.</p>
    </div>
  );
};

export default About;
