import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import ComingSoon from "./ComingSoon/ComingSoon";
import About from "./About/About";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

function App() {
  const [hasAccess, setHasAccess] = useState(false);

  const grantAccess = () => {
    setHasAccess(true);
  };

  return (
    <Router>
      {!hasAccess ? (
        <ComingSoon onAccessGranted={grantAccess} />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
